
// crs组前缀
const crsPrefix = '/crs_api';
// 直销组前缀
const rezenPrefix = '/api';

const crsApiMapping = {
  // 获取协议公司列表
  getCompanyList: `${crsPrefix}/merchant/csp/company/getCompanyList`,
  // 更新企业共享状态
  setCompanyStatus: `${crsPrefix}/merchant/csp/company/setCompanyStatus`,
  // 查询集团协议公司市场代码
  queryMarketCode: `${crsPrefix}/merchant/csp/company/queryMarketCode`,
  // 通过母子类型查询公司
  queryProtocolCompanyByMainSubType: `${crsPrefix}/merchant/csp/company/queryProtocolCompanyByMainSubType`,
  // 添加集团协议公司信息
  saveGroupProtocolCompany: `${crsPrefix}/merchant/csp/company/saveGroupProtocolCompany`,
  // POST 更新集团协议公司信息
  updateGroupProtocolCompany: `${crsPrefix}/merchant/csp/company/updateGroupProtocolCompany`,
  // 分页获取所有企业员工列表
  queryCompanyStaff: `${crsPrefix}/merchant/csp/staff/queryCompanyStaff`,
  // rezen改版csp 分页获取所有企业员工列表
  rezenQueryCompanyStaff: `${rezenPrefix}/merchant/csp/staff/queryCompanyStaff`,
  // 获取协议公司下拉列表
  getCompanyBriefList: `${crsPrefix}/merchant/csp/company/getCompanyBriefList`,
  // 设置企业员工账号状态
  setCompanyStaffStatus: `${crsPrefix}/merchant/csp/staff/setCompanyStaffStatus`,
  // csp改版，rezen设置企业员工账号状态
  rezenSetCompanyStaffStatus: `${rezenPrefix}/merchant/csp/staff/setCompanyStaffStatus`,
  // 添加集团企业员工
  addGroupCompanyStaff: `${crsPrefix}/merchant/csp/staff/addGroupCompanyStaff`,
  // rezen改版csp，新的添加集团企业员工接口
  rezenAddGroupCompanyStaff: `${rezenPrefix}/merchant/csp/staff/addGroupCompanyStaff`,
  // 更新集团企业员工
  updateGroupCompanyStaff: `${crsPrefix}/merchant/csp/staff/updateGroupCompanyStaff`,
  // csp改版后新的更新集团企业员工接口
  rezenUpdateGroupCompanyStaff: `${rezenPrefix}/merchant/csp/staff/updateGroupCompanyStaff`,
  // 查询合同列表
  queryContract: `${crsPrefix}/merchant/csp/contract/queryContract`,
  // 更新合同
  updateContract: `${crsPrefix}/merchant/csp/contract/updateContract`,
  // 查询某个合同
  getContract: `${crsPrefix}/merchant/csp/contract/getContract`,
  // 添加合同
  addContract: `${crsPrefix}/merchant/csp/contract/addContract`,
  // 查询合同品牌酒店房型对应
  queryBrandHotelRoomListByContract: `${crsPrefix}/merchant/csp/contract/queryBrandHotelRoomListByContract`,
  // 保存合同适用的品牌酒店房型
  saveBrandHotelRoomForContract: `${crsPrefix}/merchant/csp/contract/saveBrandHotelRoomForContract`,
  // 查询所有品牌酒店房型
  queryAllBrandHotelRoomType: `${crsPrefix}/merchant/csp/contract/queryAllBrandHotelRoomType`,
  // 查询合同折扣详情
  queryContractDiscount: `${crsPrefix}/merchant/csp/contract/queryContractDiscount`,
  // 设置合同折扣
  setContractDiscount: `${crsPrefix}/merchant/csp/contract/setContractDiscount`,
  // 获取渠道列表
  getCrsChannelList: `${crsPrefix}/merchant/crs/channel/getCrsChannelList`,
  // POST 查询合同预设房型
  queryContractPresetRoomType: `${crsPrefix}/merchant/csp/contract/queryContractPresetRoomType`,
  // POST 更新合同预设房型
  updateContractPresetRoomType: `${crsPrefix}/merchant/csp/contract/updateContractPresetRoomType`,
  // POST 应用合同预设房型
  useContractPresetRoomType: `${crsPrefix}/merchant/csp/contract/useContractPresetRoomType`,
  // POST 合同预设房型添加酒店
  updateContractPresetRoomTypeAddHotel: `${crsPrefix}/merchant/csp/contract/updateContractPresetRoomTypeAddHotel`,
  // POST 合同预设房型删除酒店
  updateContractPresetRoomTypeDeleteHotel: `${crsPrefix}/merchant/csp/contract/updateContractPresetRoomTypeDeleteHotel`,
  // POST 获取合同base价和关联价
  getContractBaseRate: `${crsPrefix}/merchant/crs/contractRate/getContractBaseRate`,
  // POST 查询合同关联价和酒店对应关系
  queryContractBaseRateHotel: `${crsPrefix}/merchant/crs/contractRate/queryContractBaseRateHotel`,
  // POST 设置合同关联价和酒店对应关系
  setContractBaseRateHotel: `${crsPrefix}/merchant/crs/contractRate/setContractBaseRateHotel`,
  // POST 查询酒店配置
  queryCspHotelSetting: `${crsPrefix}/merchant/csp/hotelSetting/queryCspHotelSetting`,
  // POST 获取酒店配置详情
  getCspHotelSettingDetail: `${crsPrefix}/merchant/csp/hotelSetting/getCspHotelSettingDetail`,
  // POST 保存酒店配置
  saveCspHotelSetting: `${crsPrefix}/merchant/csp/hotelSetting/saveCspHotelSetting`,
  // POST 查询集团协议公司列表
  queryGroupProtocolCompany: `${crsPrefix}/merchant/csp/company/queryGroupProtocolCompany`,
  // POST 添加合同和合同价格策略
  addContractAndPriceStrategy: `${crsPrefix}/merchant/csp/contract/addContractAndPriceStrategy`,
  // POST 更新合同和合同价格策略
  updateContractAndPriceStrategy: `${crsPrefix}/merchant/csp/contract/updateContractAndPriceStrategy`,
  // 查询取消规则列表
  queryList: `${crsPrefix}/merchant/crs/cancelRule/queryList`,
  // POST 获取合同价格策略模板
  getContractPriceStrategyTemplate: `${crsPrefix}/merchant/csp/contract/getContractPriceStrategyTemplate`,
  // POST 根据来源获取基础价信息
  getBaseRateListBySource: `${crsPrefix}/merchant/crs/rate/getBaseRateListBySource`,
  // POST 查询合同房型
  queryContractRoomType: `${crsPrefix}/merchant/csp/contract/queryContractRoomType`,
  // POST 设置合同房型
  setContractRoomType: `${crsPrefix}/merchant/csp/contract/setContractRoomType`,
  // POST 更新合同状态
  updateContractStatus: `${crsPrefix}/merchant/csp/contract/updateContractStatus`,
  // 查询合同价格策略模板
  queryContractPriceStrategyTemplate: `${crsPrefix}/merchant/csp/contract/queryContractPriceStrategyTemplate`,
  // POST 更新合同价格策略模板
  updateContractPriceStrategyTemplate: `${crsPrefix}/merchant/csp/contract/updateContractPriceStrategyTemplate`,
  // 获取渠道可选价格来源列表
  // getChannelBaseRateSourceList: `${crsPrefix}/merchant/crs/channel/getChannelBaseRateSourceList`,
  getHotelPriceLevelList: `${crsPrefix}/crs/merchant/pricingstrategy/getHotelPriceLevelList`,
  // 查询二级渠道
  queryDistributionBySystemChannel: `${crsPrefix}/merchant/ibe/distribution/queryDistributionBySystemChannel`,
  // 获取公司员工导入日志
  queryCompanyStaffImportLog: `${rezenPrefix}/merchant/csp/staff/queryCompanyStaffImportLog`,
  // 获取公司员工导入模板
  getCompanyStaffImportTemplate: `${rezenPrefix}/merchant/csp/staff/getCompanyStaffImportTemplate`,
  // 获取公司员工导入进度
  getCompanyStaffImportProgress: `${rezenPrefix}/merchant/csp/staff/getCompanyStaffImportProgress`,
  // 获取获取公司员工导入详情文件
  getCompanyStaffImportDetailFile: `${rezenPrefix}/merchant/csp/staff/getCompanyStaffImportDetailFile`,
  // 导入公司员工
  importCompanyStaff: `${rezenPrefix}/merchant/csp/staff/importCompanyStaff`,
  // 获取pms固定价合作酒店
  getPmsFixedPriceCooperateHotelList: `${crsPrefix}/merchant/csp/company/getPmsFixedPriceCooperateHotelList`,
  // 下发集团客户查找酒店
  getPmsFixedPriceDistributeToHotelList: `${crsPrefix}/merchant/csp/company/getPmsFixedPriceDistributeToHotelList`,
  // 下发集团客户
  pmsFixedPriceDistributeToHotels: `${crsPrefix}/merchant/csp/company/pmsFixedPriceDistributeToHotels`,
  // 恢复pms固定价合作
  resumePmsFixedPriceCooperate: `${crsPrefix}/merchant/csp/company/resumePmsFixedPriceCooperate`,
  // 停止pms固定价合作
  stopPmsFixedPriceCooperate: `${crsPrefix}/merchant/csp/company/stopPmsFixedPriceCooperate`,
  // 查询酒店合同价格详情
  queryContractPriceDetail: `${crsPrefix}/merchant/csp/company/queryContractPriceDetail`,
  // 查询集团协议公司下发日志
  queryCompanyPmsFixedPriceLog: `${crsPrefix}/log/crsOperateLog/queryCompanyPmsFixedPriceLog`,
  // 查询集团所有协议公司
  queryAllGroupProtocolCompany: `${crsPrefix}/merchant/csp/company/queryAllGroupProtocolCompany`,
  // 修改集团所有协议公司所属方
  updatePmsFixedPriceCompanyBelongTo: `${crsPrefix}/merchant/csp/company/updatePmsFixedPriceCompanyBelongTo`,
  // 设置协议公司价格类型
  setCompanySaleStatus: `${crsPrefix}/merchant/csp/company/setCompanySaleStatus`,
  // 合同中分销商下拉列表
  queryThirdDistribution: `${crsPrefix}/merchant/ibe/distribution/queryThirdDistribution`,
  // 查询当前登录用户是否在白名单内-api
  queryGroupProtocolCompanyWhitelistValidation: `${crsPrefix}/merchant/csp/company/queryGroupProtocolCompanyWhitelistValidation`,
  // 更新员工状态 -Alliance
  updateCompanyBusinessTravelMemberStatus: `${rezenPrefix}/company/business/travel/member/updateCompanyBusinessTravelMemberStatus`,
  // 获取员工列表 -Alliance
  queryCompanyBusinessTravelMemberByPage: `${rezenPrefix}/company/business/travel/member/queryCompanyBusinessTravelMemberByPage`,
  // 获取员工详情 -Alliance
  getCompanyBusinessTravelMember: `${rezenPrefix}/company/business/travel/member/getCompanyBusinessTravelMember`,
  // 添加员工 -Alliance
  saveCompanyBusinessTravelMember: `${rezenPrefix}/company/business/travel/member/saveCompanyBusinessTravelMember`,
  // 修改员工 -Alliance
  updateCompanyBusinessTravelMember: `${rezenPrefix}/company/business/travel/member/updateCompanyBusinessTravelMember`,

  // 获取商旅会员导入记录
  getCompanyBusinessTravelMemberImportLog: `${rezenPrefix}/company/business/travel/member/import/getCompanyBusinessTravelMemberImportLog`,
  // 获取导入模板
  getCompanyBusinessTravelMemberImportTemplate: `${rezenPrefix}/company/business/travel/member/import/getCompanyBusinessTravelMemberImportTemplate`,
  //  获取商旅会员导入记录
  queryCompanyBusinessTravelMemberImportLogByPage: `${rezenPrefix}/company/business/travel/member/import/queryCompanyBusinessTravelMemberImportLogByPage`,
  //  获取商旅会员导入记录
  getCompanyBusinessTravelMemberImportDetailLogFile: `${rezenPrefix}/company/business/travel/member/import/getCompanyBusinessTravelMemberImportDetailLogFile`,
  //  商旅会员导入
  importCompanyBusinessTravelMember: `${rezenPrefix}/company/business/travel/member/import/importCompanyBusinessTravelMember`,
};

const rezenApiMapping = {
  // 获取单位列表
  listUnit: `${rezenPrefix}/unit/listUnit`,
  // 获取操作员
  queryStaffByDisplayName: `${rezenPrefix}/staff/queryStaffByDisplayName`,
  // 获取国家列表
  getCountryList: `${rezenPrefix}/district/getCountryList`,
  // 获取省份列表
  getProvinceList: `${rezenPrefix}/district/getProvinceList`,
  // 根据省份获取城市列表
  getCityList: `${rezenPrefix}/district/getCityList`,
  // POST 根据集团ID 酒店ID 酒店名称获取酒店信息
  getHotelInfoByGroupId: `${rezenPrefix}/merchant/product/hotel/getHotelInfoByGroupId`,
  getCspCompanyPoolList: `${rezenPrefix}/merchant/csp/company/pool/getCspCompanyPoolList`,
  getCspCompanyPoolDetail: `${rezenPrefix}/merchant/csp/company/pool/getCspCompanyPoolDetail`,
  addCspCompanyFollowUpLog: `${rezenPrefix}/merchant/csp/company/pool/addCspCompanyFollowUpLog`,
  finishCspCompanyFollowUp: `${rezenPrefix}/merchant/csp/company/pool/finishCspCompanyFollowUp`,
  getBrandList: `${rezenPrefix}/merchant/product/hotel/getBrandList`,
  getHotelListByBrandOrCity: `${rezenPrefix}/merchant/product/hotel/getHotelListByBrandOrCity`,
  getPmsStaffRole: `${rezenPrefix}/merchant/pms/staff/getPmsStaffRole`,
  getUserRelationHotelsInfo: `${rezenPrefix}/merchant/product/hotel/getUserRelationHotelsInfo`,
  getPmsStaffListByPage: `${rezenPrefix}/merchant/pms/staff/getPmsStaffListByPage`,
  getCspCompanyPoolDistributeNum: `${rezenPrefix}/merchant/csp/company/pool/getCspCompanyPoolDistributeNum`,
  distributeCspCompanyPool: `${rezenPrefix}/merchant/csp/company/pool/distributeCspCompanyPool`,
  getCspCompanyPoolDistributeHistory: `${rezenPrefix}/merchant/csp/company/pool/getCspCompanyPoolDistributeHistory`,
  downLoadCspCompanyPool: `${rezenPrefix}/merchant/csp/company/pool/downLoadCspCompanyPool`,
  addBusinessTravelWhiteListStaff: `${rezenPrefix}/merchant/businessTravel/whiteList/addBusinessTravelWhiteListStaff`,
  getStaffInfoForBusinessTravelWhiteList: `${rezenPrefix}/merchant/businessTravel/whiteList/getStaffInfoForBusinessTravelWhiteList`,
  getBusinessTravelWhiteListStaff: `${rezenPrefix}/merchant/businessTravel/whiteList/getBusinessTravelWhiteListStaff`,
  deleteBusinessTravelWhiteListStaff: `${rezenPrefix}/merchant/businessTravel/whiteList/deleteBusinessTravelWhiteListStaff`,
};

// api映射关系
export default {
  ...crsApiMapping,
  ...rezenApiMapping,
};
